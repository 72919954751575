.flight-searching {
  .journey-form-option {
    button {
      width: 120px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #9c0000;
      transition: all 0.3s ease-in-out;
      font-size: 14px;
      position: relative;
      border: none;
      opacity: 0.8;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 6px 0;
        border-color: transparent #ffffff transparent transparent;
        right: 3px;
        top: 3px;
        position: absolute;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        opacity: 1;
        background-color: black;
        &:after {
          opacity: 1;
        }
      }
      &:hover {
        background-color: black;
      }
    }
  }
  .css-1s2u09g-control {
    border: 0;
  }
  span.css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1pahdxg-control {
    border: 0;
    box-shadow: none;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-14el2xx-placeholder {
    font-size: 11px;
  }
  .css-319lph-ValueContainer {
    padding: 0;
  }
  .css-qc6sy-singleValue {
    font-size: 18px;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #9c0000;
  }
  .departure-date {
    background-color: #ffffff;
    input {
      background-color: #ffffff;
    }
  }
  .css-1pahdxg-control {
    background-color: #ffffff;
  }
  .css-26l3qy-menu {
    color: #000;
    font-size: 11px;
    text-transform: lowercase;
    z-index: 9999;
    text-transform: capitalize;
  }
  .css-9gakcf-option {
    background-color: #9c0000;
  }
  .css-1n7v3ny-option {
    background-color: #9c000028;
    &:active{
      background-color: #9c000079  !important;
      
    }
  }
  .css-yt9ioa-option:active{
    background-color: #9c000079  !important;

  }
  .css-9gakcf-option:active{
    background-color: #9c000079  !important;
  }
  .journey-form {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    padding: 30px 40px;
    border-radius: 8px;
    label {
      font-size: 13px;
      color: #707070;
    }
    input {
      font-size: 18px;
      color: #12161d;
      width: 100%;
      font-weight: 600;
      &,
      &:focus,
      &:active,
      &:hover {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
    .form-check {
      label {
        color: #ffffff;
        font-size: 16px;
        padding-left: 15px;
      }
      input {
        width: 20px;
        height: 20px;
        position: relative;
        top: -2px;
      }
    }
    .switch-select-icon{
        background-color: #ECECEA;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 64%;
        left: 50%;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        border: 2px solid rgb(204, 204, 204);
        text-align: center;
        transform: translate(-50%, -50%);
        svg{
            color: #848280;
        }
    }
    .form-row-field {
      gap: 12px;
      .to-from-box {
        width: 50%;
        position: relative;
        .to-from-box-field {
          width: 50%;
          padding: 15px;
          &:first-child {
            padding-right: 35px;
          }
          &:nth-child(2) {
            padding-left: 35px;
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1px;
          height: 100%;
          background-color: #dfe0e3;
        }
          // &:after {
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   width: 35px;
          //   height: 35px;
          //   transform: translate(-50%, -50%);
          //   background-image: url(../../assets/images/icons/left-right-arrow.png);
          //   background-size: contain;
          //   background-position: center center;
          // }
      }
      .departure-date {
        width: 50%;
        gap: 12px;
        .date-field {
          position: relative;
          padding: 15px;
          //&:after{
          //  content: '';
          //  position: absolute;
          //  top:50%;
          //  right: 20px;
          //  width: 17px;
          //  height: 17px;
          //  transform: translateY(-50%);
          //  background-image: url(../../assets/images/icons/date-icon.png);
          //  background-size: contain;
          //  background-position: center center;
          //}
        }
      }
    }
    .search-button {
      padding: 0 30px;
      font-size: 14px;
      margin-left: 24px;
      img {
        padding-right: 20px;
      }
    }
  }
  &.background-white {
    .journey-form-option {
      button {
        opacity: 1;
        &.active {
          opacity: 1;
          background-color: #fff;
          color: #000;
          &:after {
            border-color: transparent #9c0000 transparent transparent;
            opacity: 1;
          }
        }
        &:hover {
          background-color: #9c0000;
          color: #ffffff;
        }
      }
    }
    .journey-form {
      background-color: #ffffff;
      .form-check {
        label {
          color: #707070;
        }
      }
      .form-row-field {
        gap: 12px;
        .css-1s2u09g-control {
          background-color: #eee !important;
        }
        .to-from-box {
          background-color: #eee !important;
          // .to-from-box-field {
          //  input{
          //    background-color: #eee !important;
          //  }
          // }
        }
      }
    }
  }
}
.select-drop-down {
  .btn-dropdown {
    background-color: transparent;
    border: none;
    font-size: 14px;
    height: 45px;
    padding: 10px 30px 10px 10px;
    position: relative;
    min-width: 130px;
    &:after {
      background-image: url(../../assets/images/icons/icon-down.png);
      background-size: contain;
      background-position: center center;
      border: none;
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      top: 17px !important;
      position: absolute;
      right: 5px;
      
    }
    .dropdown-menu {
      color: #000 !important;
      button {
        font-size: 14px !important;
        &.active {
          color: #000 !important;
          background-color: #efefef !important;
        }
      }
    }
  }
}

.passengers-dropdown {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  width: 230px;
  padding: 10px 15px !important;
  .passengers-row {
    margin-bottom: 15px;
    .img {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      margin-right: 10px;
      img {
        max-width: 20px;
      }
    }
    .quantity-option {
      button {
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
        img {
          width: 14px;
        }
        &:disabled {
          background-color: #cfcfcf;
          border: 1px solid #cfcfcf;
        }
      }
      span {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 575px) {
  .flight-searching {
    .journey-form {
      .form-row-field {
        .to-from-box {
          &:before{
            width: 100%;
            height: 1px;
          }
          width: 100%;
          .to-from-box-field {
            width: 100%;
            &:last-child {
              padding-left: 15px;
            }
          }
        }
        .departure-date {
          width: 100%;
        }
      }
      .search-button{
        margin-left: 0;
      }
    }
  }
}
