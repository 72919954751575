.job-listing__wrapper {
  .job-name {
    .organization {
      font-size: 10px;
    }
  }
}

.modal-content {
  .content-job-details {
    font-weight: 500;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  li {
    display: inline-block !important;
    padding: 0 !important;
  }
}

.jobs-search {
  font-size: inherit !important;
  height: 50px !important;
}

.ant-pagination-item-ellipsis {
  color: #fff !important;
}
