@import "../../../theme/abstracts/variables";

.dynamic-llc-map {
  height: calc(100vh - 65px);
  min-height: $min-height;
  width: 100%;
}

.maker-info {
  position: relative;
  .maker__popover-info {
    opacity: 1;
    visibility: visible;
    position: relative;
    left: initial;
    transform: translateX(0);
    padding: 0;
  }
}
#sidebar {
  width: 30%;
  position: absolute;
  top: 0;
  height: calc(100vh - 110px);
  right: 0;
  z-index: 90;
  background-color: #eeeeee;
  overflow-y: auto;
  margin: 60px 96px 60px 0;
  padding: 20px 20px;
}
.maker {
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  &__popover-info {
    position: absolute;
    bottom: 140%;
    background-color: #fff;
    color: #fff;
    padding: 10px 10px 6px 10px;
    font-size: 12px;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    width: 350px;
    transform: translateX(-50%);
    text-align: center;
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      li {
        width: 33%;
        margin-bottom: 4px;
        span {
          display: flex;
          font-size: 15px;
          justify-content: flex-start;
          align-items: center;
          color: #000;
          transition: color 0.2s ease-in-out;
          i {
            font-size: 18px;
            margin-right: 8px;
            width: 10px;
            min-width: 10px;
            height: 10px;
            background-color: #000;
            border-radius: 50%;
            &:before {
              content: "" !important;
              width: 10px;
              height: 10px;
              background-color: #000;
              border-radius: 50%;
            }
          }
          &:hover {
            color: #9c0000;
          }
        }
      }
    }
  }
  &__content {
    padding: 5px;
    background-color: #9c0000;
    color: #fff;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
  }
  &:hover {
    .maker__popover-info {
      opacity: 1;
      visibility: visible;
      bottom: 110%;
      z-index: 99999999;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .dynamic-llc-map {
    height: calc(100vh - 55px);
  }
  #sidebar {
    margin: 60px 60px 60px 0;
  }
  .maker {
    &__popover-info {
      width: 300px;
      ul {
        li {
          span {
            font-size: 14px;
            i {
              width: 8px;
              height: 8px;
              &:before {
                width: 8px;
                height: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  #sidebar {
    display: none !important;
  }
  .dynamic-llc-map {
    height: calc(100vh - 116px);

    .maker {
      &__popover-info {
        width: 230px;
        ul {
          li {
            span {
              font-size: 12px;
              i {
                width: 6px;
                height: 6px;
                &:before {
                  width: 6px;
                  height: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}
