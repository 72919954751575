@import "../../../theme/abstracts/variables";

$social-link-width: 94px;
$social-link-width-desktop: 65px;
body .social-links{
    display: none !important;
}
.social-links {
  min-height: $min-content-height;
  position: absolute;
  top: $header-height;
  left: 0;
  width: $social-link-width;
  border-right: 1px solid $border-color-primary;
  ul {
    margin: 0;
    width: $social-link-width;
    li {
      display: block;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        position: relative;
        &:after {
          content: "";
          width: 2px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          transition: background-color 0.3s ease-in-out;
        }
        i {
          font-size: 25px;
          transition: color 0.3s ease-in-out;
        }
        &:hover {
          color: $primary;
          &:after {
            background-color: $primary;
          }
        }
      }
      &.follow-us {
        position: relative;
        height: 200px;
        span {
          transform: rotate(-90deg);
          display: block;
          position: absolute;
          width: 135px;
          top: 60px;
          left: -25px;
          letter-spacing: 1px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .social-links {
    width: $social-link-width-desktop;
    ul {
      margin: 0;
      width: $social-link-width-desktop;
      li {
        display: block;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          position: relative;
          i {
            font-size: 20px;
            transition: color 0.3s ease-in-out;
          }
        }
        &.follow-us {
          position: relative;
          height: 128px;
          width: 66px !important;
          span {
            font-size: 14px;
            left: -35px;
              top: 42px;
          }
        }
      }
    }

  }

  //.follow-us span {
  //  letter-spacing: 0 !important;
  //  width: 120px !important;
  //}
}
