.terms-conditon {
  ul.dots {
    margin: 10px 0;
    li {
      position: relative;
      margin: 6px 0 6px 10px;
      padding-left: 20px;
      &:before {
        content: "";
        width: 5px;
        height: 5px;
        background: #5b5b5b;
        position: absolute;
        top: 36%;
        left: 0;
        border-radius: 50px;
      }
    }
  }
}
