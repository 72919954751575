.form-check{
  label{
    color: #ffffff;
    font-size: 16px;
    padding-left: 15px;
  }
  input{
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }
}
