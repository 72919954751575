@import "../../../theme/abstracts/variables";

.left-panel {
  width: 33%;
  background: rgba($listing-right-panel, 0.9);
  font-family: $secondary-font-family;
  position: absolute;
  z-index: 9;
  transition: all 0.3s;
  left: 0;
  top: 55px;
  .left-panel-content {
    position: relative;
    height: calc(100vh - 107px);
    // min-height: $min-height;
    min-height: 400px;
    ul {
      li {
        padding: 20px 15px 20px 65px;
        display: block;
        border-bottom: 1px solid #4e4e4e;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .panel-toggle-btn {
    background-color: $primary;
    border: none;
    position: absolute;
    z-index: 9;
    height: 80px;
    width: 80px;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: -80px;
    .up {
      width: 2px;
      height: 40px;
      transform: rotate(45deg);
      display: block;
      background-color: #ffffff;
      position: absolute;
      transition: all 0.3s;
    }
    .center {
      width: 2px;
      height: 40px;
      transform: rotate(45deg);
      display: block;
      background-color: #ffffff;
      position: absolute;
      transition: all 0.3s;
    }
    .down {
      width: 2px;
      height: 40px;
      display: block;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
  .modalOff {
    transition: all 0.3s;
    .left-panel-content {
      transform: translateX(-100%);
      transition: all 0.3s;
    }
    .panel-toggle-btn {
      left: 0;
      transition: all 0.3s;
      .up {
        transform: rotate(270deg) translatex(-10px);
        transition: all 0.3s;
      }
      .center {
        transform: rotate(90deg);
        transition: all 0.3s;
      }
      .down {
        transform: rotate(90deg) translateX(-10px);
        transition: all 0.3s;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .left-panel {
    top: 66px;

    .left-panel-content {
      // height: calc(100vh - 94px);
      height: calc(100vh - 116px);
      h3 {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
      }
      ul.bullet {
        li {
          font-size: 16px !important;
          padding: 12px 15px 12px 50px !important;
          &:before {
            top: 18px;
            left: 22px;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .panel-toggle-btn {
      right: -60px;
      width: 60px;
      height: 60px;
      .up,
      .center,
      .down {
        height: 35px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .left-panel {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .left-panel {
    width: 80%;
    .left-panel-content {
      h3 {
        padding-top: 12px !important;
        padding-bottom: 6px !important;
      }
      ul.bullet {
        li {
          font-size: 14px !important;
          padding: 8px 10px 8px 30px !important;
          &:before {
            top: 14px;
            left: 12px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    .panel-toggle-btn {
      right: -40px;
      width: 40px;
      height: 40px;
      .up,
      .center,
      .down {
        height: 25px;
      }
    }
  }
}
