@import "../../../theme/abstracts/variables";

.account {
  height: calc(100vh - 119px);
  min-height: $min-height;
  &__container {
    min-height: $min-content-height;
    padding: 80px 0;
  }
}
form {
  width: 100%;
  .password-input-con{
    position: relative;
    span{
      position: absolute;
      top: 10%;
      right: 4%;
      cursor: pointer;
      // color: lightgray;
    }
  }
  .input-field {
    position: relative;
    input {
      width: 100%;
      border: none;
      background: transparent;
      border-bottom: 1px solid #a0a0a0;
      margin: 0 0 40px !important;
      padding: 5px !important;
      transition: all 0.3s ease-in-out;
      &::placeholder {
        text-transform: capitalize;
      }
      &:focus {
        border-bottom: 1.5px solid #000 !important;
        outline: none;
        &::placeholder {
          color: #000;
        }
      }
      &.error {
        border-color: $primary;
      }
    }
    small {
      color: $primary;
      position: absolute;
      bottom: 14px;
      left: 0;
    }
  }
}

.login {
  &__register {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 1600px) {
  .account {
    height: calc(100vh - 115px);
  }
}
@media only screen and (max-width: 850px) {
  .account {
    height: calc(100vh - 100px);
  }
}
