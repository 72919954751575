.let-start-journey {
  background-color: #000;
  background-image: url(../../assets/images/journey-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  h2 {
    font-size: 50px;
  }
  h3 {
    font-size: 24px;
  }
}

@media (max-width: 757px) {
  .let-start-journey {
    h2 {
      font-size: 30px;
    }
  }
}
