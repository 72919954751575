.select-occupations{
 width: 90%;
  max-width: 350px;
}
.salary-select{
  .ant-select{
    text-align: left;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 35px;
  }
  span.ant-select-arrow {
    
    background: #9c0000;
    color: #fff !important;
    height: 33px;
    width: 44px;
    display: flex;
    justify-content: center;
    top: 7px;
    right: 1px;
    span{
      font-size: 16px;
    }
   
}
span.ant-select-selection-item {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 18px) !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #000000 !important;
}
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: #000000 !important;

}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #9c00003d !important;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: visible !important;
  white-space: unset !important;
  text-overflow: unset !important;
}