@import "./theme/abstracts/variables";

main.main {
  min-height: calc(100vh - #{$footer-height});
  // min-height: calc(100vh - #{$footer-height} - #{$header-height});
  //min-height:  $min-height;
  // margin-top: $header-height;
}

@media only screen and (max-width: 1600px) {
  main.main {
    //min-height: calc(100vh - 94px);
    min-height: calc(100vh - #{$footer-height-desktop});
    // min-height: calc(
    //   100vh - #{$footer-height-desktop} - #{$header-height-desktop}
    // );
    // margin-top: $header-height-desktop;
  }
}
@media only screen and (max-width: 850px) {
  main.main {
    //min-height: calc(100vh - 100px);
  }
}

.pre-loading {
  img {
    max-width: 70%;
  }
}
