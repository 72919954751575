@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Red+Hat+Text:wght@300;400;500;600;700&display=swap');
$primary : #9c0000;
$primary-dark : #7d0c0c;
$secondary : #282828;
$text-color: #5b5b5b;
$border-color-primary : #dadada;
$listing-right-panel : #000000;
$popup-bg : #0d0d0d;
$header-height: 65px;
$header-height-desktop: 65px;
$footer-height : 65px;
$footer-height-desktop : 50px;
$min-content-height : calc(100vh - #{$header-height });
$min-content-height-desktop : calc(100vh - #{$header-height-desktop });
$min-height : 500px;

$primary-font-family: 'Montserrat', sans-serif;
$secondary-font-family:  'Mulish', sans-serif;
$third-font-family:  'Merriweather', serif;
