

@mixin responsive($breakpoint){

  @if $breakpoint == phone{
    @media only screen and (max-width: 37.5em) {@content}   // 600/16 == 37.5em == 600px   called xs
  }
  @if $breakpoint == tab-port{
    @media only screen and (max-width: 56.25em) {@content}  // 900/16 == 56.25em == 900px called sm
  }
  @if $breakpoint == tab-land{
    @media only screen and (max-width: 75em) {@content}    // 1200/16 == 75em == 1200px called md
  }

  @if $breakpoint == small-desktop{
    @media only screen and (max-width: 87.5em) {@content}    // 1400/16 == 87.5em == 1400px
  }

  @if $breakpoint == big-desktop{
    @media only screen and (min-width: 112.5em) {@content} // 1800/16 == 112.5em == 1800px
  }
}
