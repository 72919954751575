@import "../../../theme/abstracts/variables";

.realtors-listing{
  padding: 100px 0 200px 0;
  .container{
    max-width: 1140px;
  }
  .destination-form.dvm .destination-form__title{
    color: #000 !important;
  }
  select:focus-visible {
    outline: none;
  }
  .relator-box{
    margin: 30px 0;
    h2{
      font-size: 24px;
      font-weight: bold;
      color: #252525;
      padding: 20px 0;
      font-family: $third-font-family;
    }
    .relator-box-list{
      margin: 15px 0;
      .icon-box{
        min-width: 22px;
        margin-right: 20px;
      }
      a, span{
        color: #707070;
        font-size: 18px;
        font-weight: 400;
        font-family: $secondary-font-family;
      }
    }
    &-img{
      background-color: #F3F3F3;
      padding: 50px 15px;
      position: relative;
      .img-box{
        max-width: 215px;
        height: 215px;
        img{
          width: 100%;
          mix-blend-mode: multiply;
        }
      }

      &:after{
        content: '';
        position: absolute;
        width: 5px;
        height: calc(100% - 100px);
        background-color: #9c0000;
        top: 50px;
        right: -5px;
      }
    }
  }
}
@media (max-width:600px){
  .realtors-listing{
    padding: 50px 0 50px 0;
  }
}