@import "../../../theme/abstracts/variables";

.web-footer {
  padding: 20px 0;
  background-color: #e2e3e63a;
  .footer-logo {
    max-width: 180px;
  }
  &__wrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 370px;
  }
  &__socials {
    padding-top: 50px;
    &__icons {
      cursor: pointer;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 39px;
      background-color: #898d94;
      border-radius: 50px;
      &:hover {
        background-color: #9c0000;
      }
    }
  }
  &__contact {
    padding: 50px 0 0;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    max-width: 553px;
    width: 100%;
    svg {
      margin-right: 10px;
    }
    &__phone {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  &__copyrights {
    font-size: 14px;
    color: #12161d;
    font-weight: 400;
    margin-top: 50px;
  }
}


@media only screen and (max-width: 290px) {
    .web-footer {
      &__contact {
        a {
          font-size: 13px;
        }
      }
    }
}
