
.companies-listing{
  background: #282828;
.company-row{
  box-shadow: 0 0 10px rgba(204, 204, 204, 0.04);
  background: #000000;
  margin:0 10px;
  color: #fff;
  a,span{
    font-size: 12px !important;
  }
  .company-images{
    position: relative;
    align-self: center;
  }
  .company-img{
    position: relative;
    width: 100%;
    padding-top: 60%;
    background-size: cover;
    background-position: center;
    background-repeat:  no-repeat;
    overflow: hidden ;
    .company-name{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #000;
      background-color: #efefef;

    }
  }
}
}

.moving-companies-wrapper {
  padding-bottom: 8rem !important;
}