.book-flight-page {
  padding: 100px 0;
}

.book-flight {
  .f-13 {
    font-size: 13px;
  }
  .selected {
    background-color: #fff;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.16);
    border-bottom: 4px solid #9c0000 !important;
  }
  .filters {
    .f-20 {
      font-size: 20px;
    }
    .f-13 {
      font-size: 13px;
    }
    button {
      border: solid 1px #e2e3e6;
      //   transition: all 0.3s ease-in-out;
    }
  }
  .card-header {
    background: #9c0000;
    min-height: 77px;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    .sponserd {
      outline: none !important;
      box-shadow: none !important;
      background-color: #7d0c0c !important;
      border-color: #7d0c0c !important;
    }
  }
  .select-drop-down {
    .btn-dropdown {
      &:after {
        filter: brightness(0.5);
      }
    }
  }
  .font-15 {
    font-size: 15px;
  }
  .hover {
    &:hover {
      background-color: #610000 !important;
    }
  }
  .ticket-card {
    background: #ffffff;
    border: solid 1px #e2e3e6;
    position: relative;
    .selection-box {
      min-height: 212px;
      background: #f1f1f1;
      h2 {
        font-size: 36px;
      }
    }
    .tag {
      //   width: 12%;
      padding: 10px;
      background: #ffe4e4;
      position: absolute;
      top: 0;
      left: 0;
    }
    .line-bar {
      width: 86%;
      background: #e2e3e6;
      padding-left: 86%;
      height: 1px;
      font-size: 1px;
      margin: 0 auto;
      position: relative;
      &::before {
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: -4px;
        background-color: #9c0000;
      }
      &::after {
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: -4px;
        background-color: #9c0000;
      }
    }
    .flight-details-locations{
      margin-right: 2.4rem;
      margin-left: 2.4rem;
    }
  }
  .text-black {
    color: #000;
  } 
  .css-1pahdxg-control {
    background-color: #eeeeee;
  }
  .departure-date {
    background-color: #eeeeee;
    input {
      background-color: #eeeeee;
    }
  }
  .css-1pahdxg-control {
    background-color: #eeeeee;
  }
  .booking-form {
    background-color: transparent;
    width: 100%;
    // padding: 30px 40px;
    background-color: transparent;
    label {
      font-size: 13px;
      color: #707070;
    }
    input {
      font-size: 18px;
      color: #12161d;
      width: 100%;
      font-weight: 600;
      &,
      &:focus,
      &:active,
      &:hover {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
    .form-check {
      label {
        color: #ffffff;
        font-size: 16px;
        padding-left: 15px;
      }
      input {
        width: 20px;
        height: 20px;
        position: relative;
        top: -2px;
      }
    }
    .form-row-field {
      gap: 12px;
      .to-from-box {
        width: 50%;
        position: relative;
        .to-from-box-field {
          width: 50%;
          padding: 15px;
          &:first-child {
            padding-right: 35px;
          }
          &:last-child {
            padding-left: 35px;
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1px;
          height: 100%;
          background-color: #dfe0e3;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 35px;
          height: 35px;
          transform: translate(-50%, -50%);
          background-image: url(../../assets/images/icons/left-right-arrow.png);
          background-size: contain;
          background-position: center center;
        }
      }
      .departure-date {
        width: 40%;
        gap: 12px;
        .date-field {
          position: relative;
          padding: 15px;
          //&:after{
          //  content: '';
          //  position: absolute;
          //  top:50%;
          //  right: 20px;
          //  width: 17px;
          //  height: 17px;
          //  transform: translateY(-50%);
          //  background-image: url(../../assets/images/icons/date-icon.png);
          //  background-size: contain;
          //  background-position: center center;
          //}
        }
      }
    }
    // .search-button {
    //   padding: 0 30px;
    //   font-size: 14px;
    //   margin-left: 24px;
    //   min-height: 87px !important;
    //   img {
    //     // padding-right: 20px;
    //   }
    // }
  }
  .timepicker {
    background-color: transparent;
    &:focus-visible {
      outline: none;
    }
  }
  .booking-form-option {
    button {
      width: 120px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.8);
      transition: all 0.3s ease-in-out;
      font-size: 14px;
      position: relative;
      border: none;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 6px 0;
        border-color: transparent #ffffff transparent transparent;
        right: 3px;
        top: 3px;
        position: absolute;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        background-color: #9c0000;
        &:after {
          opacity: 0.8;
        }
      }
      &:hover {
        background-color: #9c0000;
      }
    }
  }
}

.flight-card-con {
  .flight-card-left{
    padding-top: 2.0rem;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px !important;
    width: 90% !important;
    .journey-form {
      padding: 10px;
    }
  }
}
@media (max-width: 576px) {
  .book-flight-page {
    padding: 50px 0;

    .container {
      max-width: 540px !important;
      width: 100% !important;
    }
  }
}
