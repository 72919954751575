
.pagination-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  li {
    display: inline-block !important;
    padding: 0 !important;
  }
}

.ant-pagination-item-ellipsis {
  color: #fff !important;
}
// .ant-pagination-item {
//   ul.bullet li:before {
//     content: none;
//   }
// }

// li.ant-pagination-item::before {
//   content: none !important;
// }

.left-panel{
  .left-panel-content {
    .education-listing-con{
      min-height: calc(100vh - 260px);
    }
    .pagination-container{
      .ant-pagination-item,.ant-pagination-prev, .ant-pagination-next {
        padding: 0px !important;            
      }
      li{
        padding: 0px !important;
        &::before {
          content: none !important;
        }
      }
 
    }
    
  }
} 