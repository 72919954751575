@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100%{
    opacity: 1;
    transform: scale(1);

  }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
