select, .select  {
  width: 49%;
  height: 35px;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 45px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../app/assets/images/icons/down-arrow.png),
  linear-gradient(to right, #9c0000, #9c0000);
    background-size: 18px 18px, 40px 31px;
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) calc(100% - 7px), calc(100% - 1px) calc(100% - 1px);
    font-size: 13px;
    color: black;
  &:focus {
    // background-image: change
  }
}

//@media only screen and (max-width: 1600px) {
//  select {
//    height: 35px !important;
//    background-position: calc(100% - 12px) calc(100% - 8px), calc(100% - 1px) calc(100% - 1px) !important
//  }
//}

@media only screen and (max-width: 600px) {
  select {
    height: 30px;
    padding-left: 10px;
    padding-right: 30px;
    background-size: 12px 14px, 30px 26px;
    background-position: calc(100% - 9px) calc(100% - 7px), calc(100% - 1px) calc(100% - 1px);
  }
}
