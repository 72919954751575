.modal-content__container-weather{
    .content-weather-details{
        margin-left: 15px;
        margin-right: 15px;
        .weather-icon{
            width : 80px;
            height : 80px;
            img{
                width : 100%
            }
        }
        .weather-temp{
            font-size : 50px;
            line-height : 50px;
            font-weight : 700;
            sup{
                font-size : 25px;
                left: -0.2em;
            }
        }
    .statistics{
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        font-size: 14px;
        font-weight: 300;
    }
    }
    .left-side{
        .forcasting{
            background-color: #000000;
            padding: 5px 10px;
            font-size: 14px;
            margin-bottom: 15px;
            font-weight: 400;
            .forcasting-left,
            .forcasting-right{
                width: 50%;
            }
            .forcasting-left{
                padding-right: 25px;

            }
            .key-value{
                .key{
                    font-size: 12px;
                }
                .value{
                    font-weight:  600;
                    font-size: 16px;

                }
            }
        }
    }
}
@media only screen and (max-width: 1600px) {
    .modal-content__container-weather{
        .content-weather-details{
            margin-left: 12px;
            margin-right: 12px;
            .weather-icon{
                width : 60px;
                height : 60px;
                img{
                    width : 100%
                }
            }
            .weather-temp{
                font-size : 40px;
                line-height : 40px;
                sup{
                    font-size : 22px;
                }
            }
        }
        .left-side{
            padding-top: 2rem !important;
            padding-bottom: 2rem !important;
            .forcasting{
                margin-bottom: 12px;
                font-weight: 400;
            }
        }
    }

}

@media only screen and (max-width: 600px) {
    .modal-content__container-weather {
        .content-weather-details{
            .statistics{
                padding-right: 0;
                padding-left: 0;
            }
        }
        .left-side {
            .forcasting {
                .forcasting-left,
                .forcasting-right {
                    width: 100%;
                }

                .forcasting-left {
                    padding-right: 0;

                }
            }
        }
    }
}
