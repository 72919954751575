.realtor-listing{
  background: #282828;

  ul{
    li{
      padding: 20px 15px 20px 65px !important;
      display: block;
      border-bottom: 1px solid #4e4e4e;
      font-size: 20px;
      font-weight: 600;
    }
  }

}

