.partner-with-us{

  .radio-option{
    .form-control{
      background-color: transparent;
      .form-check{
        font-size: 20px;
        font-weight: 600;
        margin-right: 30px;
      }
    }
    .invalid-feedback{
      display: none;
    }
    .form-group{
      margin-bottom: 0;
    }
  }
  .form-check-label{
    color: #000;
  }
  .form-control{
    font-size: 16px;
    padding: 18px;
    min-height: 48px;
    border-radius: 5px;
    border-color: #000 !important;
    &.is-invalid{
      border-color: #dc3545 !important;
    }
  }
  .form-group{
    margin-bottom: 30px;
    position: relative;
    .invalid-feedback{
      position: absolute;
      font-size: 12px;
    }
  }



}
@media only screen and (max-width: 600px) {
  .partner-with-us{
    .container{
      width: 100%;
      padding: 0 15px;
    }
    .radio-option{
      .form-control{
        background-color: transparent;
        .form-check{
          font-size: 16px;
        }
      }
    }
    .form-control{
      min-height: 35px;

    }
  }
}