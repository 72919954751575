@import "../../../theme/abstracts/variables";

.list-change {
  background-color: #161616;
  width: 100%;
  &__container {
    min-height: calc(100vh - 65px);
    padding: 0 60px;
  }
}
#accordion {
  margin-bottom: 100px;
}
.card {
  margin-top: 20px;
  border: none !important;
}
.card-header {
  padding: 0 !important;
  background: rgb(201, 31, 32);
  background: linear-gradient(
    90deg,
    rgba(201, 31, 32, 1) 21%,
    rgba(156, 0, 1, 1) 75%
  );
  font-size: 24px;

  color: white;

  .card-link {
    display: block;
    width: 100%;
    padding: 10px 20px;
    position: relative;
    &:after {
      display: none !important;
    }
    &:hover {
      color: #fff;
    }
    .icon-plus-circle {
      display: none;
    }
    .icon-minus-circle {
      display: inline-flex;
    }
    i {
      width: 24px;
      font-size: 24px;
    }
    &.collapsed {
      .icon-plus-circle {
        display: inline-flex;
      }
      .icon-minus-circle {
        display: none;
      }
    }
    //&:after{
    //  content:"-";
    //  font-size:24px;
    //  width:30px;
    //  height:30px;
    //  padding-bottom:2px;
    //  border-radius:50%;
    //  display:flex;
    //  justify-content:center;
    //  align-items:center;
    //  color:white;
    //  background-color: rgba(0,0,0,0.5);
    //  position:absolute;
    //  right:20px;
    //  top:0;
    //  bottom:0;
    //  margin:auto;
    //  transition: all  .2s;
    //
    //
    //}
    //&.collapsed:after{
    //  content:"+";
    //  padding-bottom:0;
    //  transition: all .2s;
    //}
  }
}
.card-body {
  color: #fff;
  background-color: #000;
  padding: 40px 20px !important;
  .lc-steps-header{
    color: #fff;
  }
  h1 {
    font-weight: bold;
    margin-bottom: 20px !important;
  }
  p,
  .ans {
    font-size: 14px !important;
    font-weight: 200;
    margin-bottom: 30px;
  }
  .ans {
    color: #bdb7b7;
    font-weight: 600;
    a {
      color: $primary;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: $primary-dark;
      }
    }
  }
  p.bold {
    margin-bottom: 10px;
    font-size: 18px !important;
    font-weight: 600;
  }
}
@media only screen and (max-width: 1600px) {
  .list-change {
    &__container {
      min-height: calc(100vh - 50px);
    }
  }
}
@media only screen and (max-width: 850px) {
  .list-change {
    &__container {
      min-height: calc(100vh - 50px);
      padding: 0 60px 0 0;
    }

    .dvm {
      h1 {
        font-size: 16px !important;
      }
    }
  }
  .card-header {
    font-size: 16px !important;

    color: white;

    .card-link {
      display: block;
      width: 100%;
      padding: 10px 20px;
      position: relative;
      &:hover {
        color: #fff;
      }
      &:after {
        content: "-";
        font-size: 24px;
        width: 30px;
        height: 30px;
        padding-bottom: 2px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.2s;
      }
      &.collapsed:after {
        content: "+";
        padding-bottom: 0;
        transition: all 0.2s;
      }
    }
  }
  .card-body {
    color: #fff;
    background-color: #000;
    padding: 40px 20px !important;
    
    h1 {
      font-weight: bold;
      margin-bottom: 14px !important;
      font-size: 14px;
    }
    p,
    .ans {
      font-size: 10px !important;
      font-weight: 200;
      margin-bottom: 30px;
    }
    .ans {
      color: #bdb7b7;
      font-weight: 600;
    }
    p.bold {
      margin-bottom: 10px;
      font-size: 12px !important;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 600px) {
  .list-change {
    background-color: #161616;
    width: 100% !important;
    &__container {
      padding: 0 15px;
      width: 100% !important;
    }
  }
  #accordion {
    margin: 0 15px;
  }
  .card-body {
    padding: 20px 15px !important;
    h1 {
      font-weight: bold;
      margin-bottom: 12px !important;
    }
    p,
    .ans {
      margin-bottom: 10px;
    }
    p.bold {
      margin-bottom: 10px;
    }
  }
}
