.btn-transparent {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 45px;
  min-width: 160px;
  outline: none;
  .loading {
    animation: spin 2s linear infinite;
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
  &.btn-small {
    min-width: 100px;
  }
}
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  font-size: 16px;
  &:focus,
  &:active,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $primary-dark !important;
    border-color: $primary-dark !important;
  }
  &:disabled {
    background-color: $primary;
    border-color: $primary;
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.btn-dark {
  background-color: #000000;
  border-color: #000000;
  font-size: 16px;
  &:focus,
  &:active,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $primary-dark !important;
    border-color: $primary-dark !important;
  }
}
