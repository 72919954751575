@import "../../../theme/abstracts/variables";
.landing-page {
  //background-image: url(../../assets/images/icons/background.png);
  //min-height: 100vh;
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: center center;
  //position: relative;
  //overflow: hidden;

 .main-wrapper * {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
  }

  body {
    width: 100vw;
    height: 100vh;
    background: #d4e157;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn,
  .destination__btn {
    transition: all 0.5s;
  }
  .btn:hover,
  .destination__btn:hover {
    transition: all 0.5s;
    background-color: #000 !important;
    transform: scale(1.03);
  }
  .btn-dark:hover {
    transition: all 0.5s;
    background-color: #7d0c0c !important ;
  }
  .modal-dialog.modal-dialog-centered.modal-lg {
    margin-left: auto;
    margin-right: auto;
  }
  &.main-wrapper {
    min-height: 100vh;
  }
  .banner-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__main-wrapper {
    position: relative;
    z-index: 2;
  }
  header {
    height: 90px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    &.header__bg {
      background-color: #000;
    }
    .header-container {
      width: 100%;
      max-width: 1300px;
      .user-action-btns,
      .logo {
        min-width: 100px;
        padding: 0 10px;
        max-width: 100px;
        img {
          max-width: 100%;
        }
      }
      .nav-link {
        width: 100%;
        li {
          padding: 0 15px;
          font-size: 16px;
          color: #f6f7f8;
          font-weight: 400;
          position: relative;
          a {
            position: relative;
            &:after {
              content: "";
              width: 0;
              height: 1px;
              background-color: #fff;
              position: absolute;
              left: 50%;
              bottom: -4px;
              transform: translateX(-50%);
              transition: all 0.3s ease-in-out;
            }
            &.active,
            &:hover {
              color: #fff;
              &:after {
                width: 100%;
              }
            }
          }
          ul.nested {
            position: absolute;
            width: 260px;
            background: #fff;
            box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
            top: calc(100% + 0px);
            transform: translateY(50px);
            padding: 0;
            opacity: 0;
            visibility: hidden;
            // transition: all 0.5s ease-in-out;
            z-index: 99;
            li {
              display: block;
              padding: 0;
              a {
                color: #000;
                padding: 10px 15px;
                display: block;
                &:after {
                  display: none;
                }
                &:hover {
                  background-color: $primary;
                  color: #fff;
                }
              }
              &:not(:last-child) {
                border-bottom: 1px solid #ccc;
              }
            }
          }
          &:hover {
            ul.nested {
            transition: all 0.5s ease-in-out;
              transform: translateY(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
    .btn-contact {
      color: #ffffff;
      background-color: transparent;
      border: 1px solid #ffffff;
      padding: 6px 30px;
      text-transform: uppercase;
      font-size: 16px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $primary;
      }
      i {
        display: none;
        font-size: 22px;
      }
    }
    .menu-toggle {
      width: 44px;
      height: 44px;
      box-shadow: none;
      border: none;
      transition: background-color 0.3s ease-in-out;
      display: none !important;
      position: fixed;
      top: 12px;
      right: 12px;
      z-index: 999;
      &:active,
      &:focus,
      &:hover {
        background-color: $primary-dark !important;
      }
      .nav-icon {
        width: 30px;
        &:after,
        &:before,
        span {
          background-color: #fff;
          border-radius: 3px;
          content: "";
          display: block;
          height: 3px;
          margin: 5px 0;
          transition: all 0.2s ease-in-out;
        }
      }
      &.active {
        .nav-icon {
          &:before {
            transform: translateY(5px) rotate(135deg);
          }
          &:after {
            transform: translateY(-11px) rotate(-135deg);
          }
          span {
            transform: scale(0);
          }
        }
      }
    }
  }
  section.destination {
    padding-top: 80px;
    min-height: calc(100vh - 80px);
    .destination__content {
      padding: 30px 0;
    }
  }
  section.container.lp-about-us {
    width: 88%;
    .lp-about-us-content {
      margin-top: 30px;
      img {
        // position: relative;
        // top: 21px;
      }
      p {
        font-family: mulish;
        max-width: 85%;
      }
    }
  }
  footer {
    height: 60px;
  }
  .destination {
    &__sub-heading {
      font-size: 30px;
      color: #ffffff;
      text-transform: capitalize;
      font-weight: 400;
    }
    &__heading {
      font-size: 80px;
      font-weight: bold;
      color: #ffffff;
      text-transform: capitalize;
      line-height: 95px;
    }
    &__select {
      width: 560px;
      height: 60px;
      background-color: #0000009e;
      color: white;
      font-size: 18px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAOVBMVEUAAAD/4Iv/5o7/993/6av/6q3/9tr/34v/+OH/66z/+OT/4Yz/6qz/5I3/+OP/4oz/5I3/3YoAAAASZEPSAAAAEnRSTlMAQkb4vcD4N/q++zy+OPs1JiUnWrjfAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+QGEhASBFfujjoAAABVSURBVCjPxdBLDoAgEATRVkHGv97/sgZDiEgt3Bh7+WpWI/25hrlV58h9r2AD+DiJyuVQklcleywzelEKv5WH51J5KuDSsjpvG70hGN3H7ce7v3+/E65cAmfigdzKAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA2LTE4VDE2OjE4OjA0KzAwOjAwfgm8YQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNi0xOFQxNjoxODowNCswMDowMA9UBN0AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC);
      padding: 0 22px;
      background-repeat: no-repeat;
      background-position: calc(100% - 21px) calc(100% - 20px),
        calc(100% - 3px) calc(100% - 1px);
      border: none;
      &:focus {
        outline: none;
      }
    }
    &__btn {
      width: 560px;
      height: 60px;
      background: #f6f7f8;
      color: #9c0000;
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;
      border: none;
      border-radius: 3px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

.lp {
  background-color: #fff;
  .sub-title-heading {
    font-family: $secondary-font-family;
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
    color: #9c0000;
    letter-spacing: 2px;
    margin-bottom: 25px;
    display: inline-block;
    &:after {
      content: "";
      width: 50px;
      height: 0.5px;
      position: absolute;
      top: 50%;
      right: -70px;
      transform: translateY(-50%);
      background: #666666;
    }
  }
  &-primary-heading {
    font-family: $third-font-family;
    font-size: 45px;
    color: #000;
    font-weight: 900;
    margin-bottom: 40px;
  }
  &-about-us {
    &-content {
      p {
        font-size: 16px;
        max-width: 490px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 30px;
      }
      img {
        margin-top: 20px;
        max-width: 225px;
        width: 100%;
      }
    }
    &-img {
      .quote {
        position: absolute;
        bottom: 0;
        left: 52%;
        background-color: #12161d;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        padding: 40px 50px;
        width: 300px;
        transform: translateX(-52%);
        &:before {
          content: "";
          background-image: url(../../assets/images/landingPageAssets/quote.png);
          width: 60px;
          height: 60px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          left: -30px;
        }
      }
    }
  }
  &-our-vision {
    margin-top: 200px;
    background-color: #0f1013;
    color: #ffffff;
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    &-img .vision-img {
      height: 670px;
      object-fit: cover;
      object-position: right;
    }

    &-img {
      transform: translateY(-100px);
      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &,
        &:active,
        &:focus,
        &:hover {
          background: transparent;
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
    &-content {
      padding: 70px 0 70px 100px;
      max-width: 650px;
      .lp-primary-heading {
        margin-bottom: 25px;
      }
      p {
        font-weight: 400;
        margin-bottom: 0;
        max-width: 80%;
      }
      ul {
        margin-top: 30px;
        margin-bottom: 20px;
        li {
          padding-left: 27px;
          margin-bottom: 12px;
          font-weight: 400;
          position: relative;
          &:before {
            content: "";
            background-image: url(../../assets/images/landingPageAssets/checked-bullet.png);
            width: 17px;
            height: 17px;
            position: absolute;
            top: 13px;
            transform: translateY(-50%);
            background-size: contain;
            left: 0;
          }
        }
      }
      .btn {
        text-transform: uppercase;
      }
    }
  }
  &-services {
    padding: 120px 0;
    button {
      box-shadow: 1px 11px 17px 6px rgba(0, 0, 0, 0.3);
      &:hover {
        box-shadow: 1px 11px 17px 6px rgba($primary, 0.3) !important;
      }
    }
    &-row {
      margin-top: 80px;
      &-content {
        padding: 20px;
        .lp-services-heading {
          font-family: $secondary-font-family;
          font-size: 35px;
          color: #000;
          margin-bottom: 25px;
        }
        p {
          font-size: 16px;
          line-height: 32px;
          max-width: 605px;
          font-weight: 500;
        }
        button, a {
          margin-top: 40px;
        }
      }
      &-img {
        width: 100%;
        position: relative;
        padding: 20px;
        img {
          width: 100%;
          border-radius: 4px;
          box-shadow: 2px 3px 12px 12px rgba(0, 0, 0, 0.1);
          position: relative;
        }
        &:before {
          content: "";
          background-image: url(../../assets/images/landingPageAssets/circle-icon.png);
          width: 101px;
          height: 76px;
          position: absolute;
          background-size: contain;
          right: -12px;
          background-repeat: no-repeat;
          top: -13px;
        }
      }
      &:nth-child(odd) {
        flex-direction: row-reverse;
        .lp-services-row-content {
          margin-left: auto;
          padding: 20px 20px 20px 50px;
        }
        .lp-services-row-img {
          &:before {
            left: -12px;
            top: -13px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  &-newsletter {
    position: relative;
    background-color: #fbfbfb;
    &:before {
      content: "";
      background-image: url(../../assets/images/landingPageAssets/newsletter-bg.png);
      padding: 100px;
      background-size: cover;
      background-position: center;
      bottom: 0;
      left: 0;
      width: 100%;
      // height: calc(100% - 50px);
      height: 100%;
      position: absolute;
      padding-left: 20px;
    }
    .newletter-img {
      margin-top: -50px;
    }
    &-content {
      // padding-top: 200px;
      // padding-bottom: 100px;
      h3 {
        font-size: 34px;
      }
      p {
        max-width: 600px;
        font-weight: 500;
      }
      form {
        position: relative;
        margin-top: 30px;
        // width: 80%;
        input {
          width: 100%;
          // max-width: 550px;
          height: 70px;
          padding: 12px 100px 12px 20px;
          border: 1px solid #ffffff;
          font-size: 13px;
          font-family: mulish;
        }
        button {
          // transform: translateX(-100px);
          // position: relative;
          z-index: 1;
          font-size: 17px;
          font-weight: bold;
          font-family: merriweather;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -40px;
          &:hover {
            transform: translateY(-50%) !important ;
          }
        }
      }
    }
  }
  &-footer {
    padding: 50px 0;
    background-color: #e2e3e63a;
    &__wrapper {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      min-height: 370px;
    }
    &__socials {
      padding-top: 50px;
      &__icons {
        cursor: pointer;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        background-color: #898d94;
        border-radius: 50px;
        &:hover {
          background-color: #9c0000;
        }
      }
    }
    &__contact {
      padding: 50px 0 0;
      color: #000;
      font-size: 18px;
      font-weight: 400;
      max-width: 553px;
      width: 100%;
      svg {
        margin-right: 10px;
      }
      &__phone {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
    &__copyrights {
      font-size: 14px;
      color: #12161d;
      font-weight: 400;
      margin-top: 50px;
    }
  }
  &-how-it-work {
    background-color: #fbfbfb;
    padding: 100px 0 200px 0;
    &-content {
      max-width: 950px;
      margin: 0 auto 60px auto;
      p {
        font-size: 16px;
        line-height: 32px;
        font-weight: 500;
      }
    }
    .slick-list {
      // padding: 0 20% 0 20% !important;
      .banner-slide {
        cursor: grab;
      }
    }
    .slick-arrow {
      &:focus,
      & {
        display: block;
        position: absolute;
        bottom: -100px;
        background: #9c0000;
        left: 50%;
        z-index: 1;
        top: inherit;
        transform: translateX(-50%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      &:hover {
        background-color: #000;
      }
      &:before {
        content: "";
        background-image: url(../../assets/images/landingPageAssets/long-arrow-left.png);
        width: 28px !important;
        height: 15px;
        background-size: contain;
        opacity: 1;
        background-repeat: no-repeat;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
      &.slick-prev {
        left: calc(50% - 40px);
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next {
        left: calc(50% + 40px);
      }
    }
  }
  &-footer {
    padding: 20px 0;
    background-color: #e2e3e63a;
    .footer-logo {
      max-width: 180px;
    }
    &__wrapper {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      min-height: 370px;
    }
    &__socials {
      padding-top: 50px;
      &__icons {
        cursor: pointer;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        background-color: #898d94;
        border-radius: 50px;
        &:hover {
          background-color: #9c0000;
        }
      }
    }
    &__contact {
      padding: 50px 0 0;
      color: #000;
      font-size: 18px;
      font-weight: 400;
      max-width: 553px;
      width: 100%;
      svg {
        margin-right: 10px;
      }
      &__phone {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
    &__copyrights {
      font-size: 14px;
      color: #12161d;
      font-weight: 400;
      margin-top: 50px;
    }
  }
}
@media only screen and (min-width: 1600px) {
  .landing-page {
    .lp-about-us {
      width: 70% !important;
    }
    .lp-newsletter {
      form {
        width: 80%;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .lp {
    &-services {
      &-row {
        margin-top: 80px;
        &-content {
          padding: 20px;
          .lp-services-heading {
            font-family: $secondary-font-family;
            font-size: 35px;
            color: #000;
            margin-bottom: 30px;
          }
          p {
            font-size: 16px;
            line-height: 32px;
            max-width: 605px;
            font-weight: 500;
          }
          button, a {
            margin-top: 50px;
          }
        }
        &-img {
          width: 100%;
          position: relative;
          padding: 20px;
          img {
            width: 100%;
            border-radius: 4px;
            box-shadow: 2px 3px 12px 12px rgba(0, 0, 0, 0.1);
            position: relative;
          }
          &:before {
            content: "";
            background-image: url(../../assets/images/landingPageAssets/circle-icon.png);
            width: 101px;
            height: 76px;
            position: absolute;
            background-size: contain;
            right: -12px;
            background-repeat: no-repeat;
            top: -13px;
          }
        }
        &:nth-child(odd) {
          flex-direction: row-reverse;
          .lp-services-row-content {
            margin: 20px 0 40px 0;
          }
          .lp-services-row-img {
            &:before {
              left: -12px;
              top: -13px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &-newsletter {
      position: relative;
      background-color: #fbfbfb;
      &:before {
        content: "";
        background-image: url(../../assets/images/landingPageAssets/newsletter-bg.png);
        padding: 100px;
        background-size: cover;
        background-position: center;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 100px);
        position: absolute;
      }
      &-content {
        padding-top: 200px;
        padding-bottom: 100px;
        p {
          max-width: 600px;
          font-weight: 500;
        }
        form {
          position: relative;
          margin-top: 30px;
          input {
            width: 100%;
            max-width: 550px;
            height: 70px;
            padding: 12px 100px 12px 20px;
            border: 1px solid #ffffff;
          }
          button {
            transform: translateX(-100px);
            position: relative;
            z-index: 2;
          }
        }
      }
    }
    &-how-it-work {
      background-color: #fbfbfb;
      padding: 100px 0 200px 0;
      &-content {
        max-width: 950px;
        margin: 0 auto 60px auto;
        p {
          font-size: 16px;
          line-height: 32px;
          font-weight: 500;
        }
      }
      .slick-list {
        padding: 0 20% 0 20% !important;
      }
      .slick-arrow {
        &:focus,
        & {
          display: block;
          position: absolute;
          bottom: -100px;
          background: #9c0000;
          left: 50%;
          z-index: 1;
          top: inherit;
          transform: translateX(-50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        &:hover {
          background-color: #000;
        }
        &:before {
          content: "";
          background-image: url(../../assets/images/landingPageAssets/long-arrow-left.png);
          width: 28px !important;
          height: 15px;
          background-size: contain;
          opacity: 1;
          background-repeat: no-repeat;
          font-size: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
        &.slick-prev {
          left: calc(50% - 40px);
          &:before {
            transform: rotate(180deg);
          }
        }
        &.slick-next {
          left: calc(50% + 40px);
        }
      }
    }
  }
}
@media only screen and (max-width: 968px) {
  .landing-page {
    header {
      padding-right: 75px;
      .header-container {
        justify-content: space-between !important;
        .user-action-btns,
        .logo {
          min-width: 160px;
          padding: 0 10px;
          img {
            max-width: 100%;
          }
        }
        .nav-link {
          position: fixed;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100vh;
          background: rgba(0, 0, 0, 0.6);
          max-width: 100%;
          z-index: 999;
          opacity: 0;
          visibility: hidden;
          ul.main-menu {
            background-color: #000;
            width: 350px;
            max-width: 80%;
            position: fixed;
            height: 100vh;
            top: 0;
            right: 0;
            padding: 80px 0 20px 0;
            transform: translateX(350px);
            transition: all 0.3s ease-in-out;
            li {
              display: block;
              padding: 12px 15px;
              border-bottom: 1px solid $primary;
              ul.nested {
                position: relative;
                width: 100%;
                background: transparent;
                box-shadow: none;
                top: initial;
                transform: translateY(0);
                padding: 0;
                display: none;
                transition: all 0.5s ease-in-out;
                z-index: 99;
                li {
                  display: block;
                  padding: 0;
                  a {
                    color: #fff;
                    padding: 10px 15px;
                    display: block;
                    &:after {
                      display: none;
                    }
                    &:hover {
                      background-color: $primary;
                      color: #fff;
                    }
                  }
                  &:not(:last-child) {
                    border-bottom: 1px solid $primary;
                  }
                  &:last-child {
                    border-bottom: none;
                    a {
                      padding-bottom: 0;
                    }
                  }
                }
              }
              &:hover {
                ul.nested {
                  display: block;
                }
              }
            }
          }
          &.active {
            opacity: 1;
            visibility: visible;
            ul {
              transform: translateX(0);
            }
          }
        }
      }
      .btn-contact {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        span {
          display: none;
        }
        i {
          display: inline-flex;
        }
      }

      .menu-toggle {
        display: flex !important;
      }
    }
    .lp {
      .sub-title-heading {
        font-size: 16px;
        color: #9c0000;
        margin-bottom: 0px;
      }
      &-primary-heading {
        font-size: 25px;
        margin-bottom: 20px !important;
        text-align: left !important;
        br {
          display: none;
        }
      }
      &-about-us {
        &-content {
          p {
            font-size: 16px;
          }
          img {
            margin-top: 20px;
            max-width: 225px;
            width: 100%;
          }
        }
        &-img {
          .quote {
            font-size: 12px;
            padding: 20px;
            max-width: 70%;
            width: 100%;

            &:before {
              content: "";

              width: 40px;
              height: 40px;
            }
          }
        }
      }
      &-our-vision {
        margin-top: 100px;
        &-img {
          transform: translateY(-50px);

          button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &,
            &:active,
            &:focus,
            &:hover {
              background: transparent;
              border: none;
              box-shadow: none;
              outline: none;
            }
          }
        }
        &-content {
          padding: 20px 5px;
          max-width: 650px;
          .lp-primary-heading {
            font-size: 25px;
          }
          p {
            font-weight: 400;
            margin-bottom: 0;
          }
          ul {
            margin-top: 30px;
            margin-bottom: 20px;
            li {
              padding-left: 27px;
              margin-bottom: 12px;
              font-weight: 400;
              position: relative;
              &:before {
                content: "";
                background-image: url(../../assets/images/landingPageAssets/checked-bullet.png);
                width: 17px;
                height: 17px;
                position: absolute;
                top: 13px;
                transform: translateY(-50%);
                background-size: contain;
                left: 0;
              }
            }
          }
        }
      }
      &-services {
        padding: 40px 0;
        &-row {
          margin-top: 0px;
          &-content {
            padding: 0px;
            .lp-services-heading {
              font-size: 25px;
            }
            p {
              font-size: 14px;
            }
            button, a {
              margin-top: 50px;
            }
          }
          &-img {
            margin-top: 50px;
            img {
              width: 100%;
              border-radius: 4px;
              box-shadow: 2px 3px 12px 12px rgba(0, 0, 0, 0.1);
              position: relative;
            }
          }
        }
      }
      &-newsletter {
        &-content {
          padding-top: 150px;
          padding-bottom: 50px;

          form {
            position: relative;
            margin-top: 30px;
            input {
              height: 60px;
            }
            button {
              margin-top: 10px;
              transform: translateX(0);
            }
          }
        }
      }
      &-how-it-work {
        padding: 60px 0 140px 0;
        &-content {
          p {
            font-size: 16px;
            line-height: 32px;
            font-weight: 500;
          }
        }
        .slick-list {
          padding: 0 !important;
        }
        .slick-arrow {
          &:focus,
          & {
            display: block;
            position: absolute;
            bottom: -100px;
            background: #9c0000;
            left: 50%;
            z-index: 1;
            top: inherit;
            transform: translateX(-50%);
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          &:hover {
            background-color: #000;
          }
          &:before {
            content: "";
            background-image: url(../../assets/images/landingPageAssets/long-arrow-left.png);
            width: 28px !important;
            height: 15px;
            background-size: contain;
            opacity: 1;
            background-repeat: no-repeat;
            font-size: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
          }
          &.slick-prev {
            left: calc(50% - 40px);
            &:before {
              transform: rotate(180deg);
            }
          }
          &.slick-next {
            left: calc(50% + 40px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .landing-page {
    header {
      height: 80px;
      .header-container {
        padding-top: 0;
        .user-action-btns,
        .logo {
          min-width: 80px;
        }
      }
      .menu-toggle {
        top: 17px;
      }
    }
    .lp-about-us {
      &-content p {
        max-width: 100% !important;
      }
    }
    .lp-our-vision {
      margin-top: 50px;
      padding-bottom: 30px;
      &-img {
        transform: initial;
        img {
          height: auto;
          object-fit: contain;
          object-position: unset;
          padding-left: 20px;
          padding-top: 20px;
        }
      }
    }
    .lp-how-it-work {
      &-content {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .lp-services {
      &-heading {
        margin-bottom: 10px;
      }
      &-row-img {
        padding: 0;
        margin-bottom: 20px;
      }
      &-row-content {
        margin: 0 !important;
        padding: 0 !important;
        button, a {
          margin-top: 10px;
        }
      }
    }
    .destination {
      &__sub-heading {
        font-size: 24px;
      }
      &__heading {
        font-size: 35px;
        line-height: 45px;
      }
      &__select {
        width: 95%;
        height: 45px;
        font-size: 16px;
        padding: 0 15px;
        background-position: calc(100% - 21px) calc(100% - 20px),
          calc(100% - 3px) calc(100% - 1px);
        border: none;
      }
      &__btn {
        width: 95%;
        height: 45px;
        font-size: 18px;
      }
    }
    footer {
      p {
        font-size: 12px;
        text-align: center;
        span {
          display: block;
        }
      }
    }
    .lp {
      .sub-title-heading {
        &:after {
          content: none;
          // right: 0;
        }
      }
      &-newsletter {
        &:before {
          height: 100%;
        }
        .newletter-img {
          margin-top: 40px;
        }
        &-content {
          padding-top: 40px;
          padding-bottom: 50px;

          form {
            flex-direction: column;
            input {
              height: 50px;
            }
            button {
              width: 100%;
              position: static;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 290px) {
  .landing-page {
    .lp-footer {
      &__contact {
        a {
          font-size: 13px;
        }
      }
    }
  }
}
