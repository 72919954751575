
$charade: #000;
$bluebell: #ffffff;
.loading{
    width: 50px;
    height: 50px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &.red-border{
        div{
            border-color: #9c0000;
        }
    }
    div {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 6px solid $bluebell;
        animation: 1.5s ripple infinite;

        &:nth-child(2) {
            animation-delay: 0.5s;
        }
    }
    &.black{
        div {
            border: 6px solid $charade;
        }
    }
}


@keyframes ripple {
    from {
        transform: scale(0);
        opacity: 1;
    }

    to {
        transform: scale(1);
        opacity: 0;
    }
}
