.about-us {
  background-color: #000;
  background-image: url(../../assets/images/icons/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  margin-top: 66px;
  color: #fff;  
  p {
    font-size: 18px;
  }
  h2{
    color: #fff;
  }
  .lp-services-row-img{
    float: left;
    width: 538px;
    height: 660px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

