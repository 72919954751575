@charset "UTF-8";

@font-face {
  font-family: "dynamic-llc";
  src:url("fonts/dynamic-llc.eot");
  src:url("fonts/dynamic-llc.eot?#iefix") format("embedded-opentype"),
    url("fonts/dynamic-llc.woff") format("woff"),
    url("fonts/dynamic-llc.ttf") format("truetype"),
    url("fonts/dynamic-llc.svg#dynamic-llc") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dynamic-llc" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "dynamic-llc" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\62";
}
.icon-fb:before {
  content: "\65";
}
.icon-google-plus:before {
  content: "\66";
}
.icon-linkedin:before {
  content: "\68";
}
.icon-twitter:before {
  content: "\69";
}
.icon-close-envelope:before {
  content: "\6a";
}
.icon-twitter-black-shape:before {
  content: "\6b";
}
.icon-instagram:before {
  content: "\67";
}
.icon-down-open-big:before {
  content: "\6c";
}
.icon-down-open:before {
  content: "\6d";
}
.icon-telephone:before {
  content: "\6e";
}
.icon-envelope:before {
  content: "\6f";
}
.icon-envelope-o:before {
  content: "\70";
}
.icon-user:before {
  content: "\71";
}
.icon-reload:before {
  content: "\61";
}
.icon-searching-magnifying-glass:before {
  content: "\63";
}
.icon-cloud:before {
  content: "\64";
}
.icon-climate:before {
  content: "\72";
}
.icon-sale:before {
  content: "\73";
}
.icon-hotel:before {
  content: "\74";
}
.icon-graduate-cap:before {
  content: "\75";
}
.icon-indeed-logo:before {
  content: "\76";
}
.icon-star:before {
  content: "\77";
}
.icon-star-half-o:before {
  content: "\78";
}
.icon-star-o:before {
  content: "\79";
}
.icon-logout:before {
  content: "\7a";
}
.icon-login:before {
  content: "\41";
}
.icon-plus-circle:before {
  content: "\42";
}
.icon-minus-circle:before {
  content: "\43";
}
.icon-filter:before {
  content: "\44";
}
