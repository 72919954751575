.banner-cover {
  height: 50vh;
  min-height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 90px 15px;
  h2 {
    font-size: 100px;
    color: #fff;
  }
}
@media (max-width: 768px) {
  .banner-cover {
    height: auto;
    min-height: 400px;
    h2 {
      font-size: 70px;
    }
  }
}
@media (max-width: 550px) {
  .banner-cover {
    // height: auto;
    min-height: 280px;
    h2 {
      font-size: 40px;
    }
  }
}
