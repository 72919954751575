@import "../../../theme/abstracts/variables";

.footer{
  // position: relative;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  &__container{
    height: $footer-height;
    font-size: 10px;
    padding: 0 10%;
      position: relative;
      z-index: 999;
      justify-content:space-between;
    .footer-menu{
        display:flex;
        justify-content:center;
        align-items:center;
        ul{
            display:flex;
            justify-content:center;
            align-items:center;
        }
      li{

        padding: 0 15px;
        &:not(:last-child){
          border-right: 1px solid #fff;
        }
      }
        li.noboder {
            border: none !important;
            font-size:20px;
        }
      li a:hover{
          color:#282828;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .footer{
    &__container{
      height: $footer-height-desktop;
        justify-content:space-between;
        .footer-menu{
            ul{
                display:flex;
                justify-content:center;
                align-items:center;
            }

            li.noboder {
                font-size:20px;
            }
        }
    }
  }
}
@media only screen and (max-width: 975px) {
    .footer {
        &__container {
            padding: 0 10px;
        }
    }
}

@media only screen and (max-width: 600px) {
  .footer{
    &__container{
      .footer-menu{
        ul{
          li{
            padding: 0 4px;
          }
          &:last-child{
            margin-left: 10px !important;
          }
        }
      }
    }
  }
}
