@import "../../../theme/abstracts/variables";

.dl-modal{
  position: fixed;
  top:0;
  left: 0;
  width :100%;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0,0,0,.8);
  transition: all .4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  &.active{
    opacity: 1;
    visibility: visible;
  }
  &__content{
    max-width: 1000px;
    width: 80%;
    padding-top: 40px;
    position: relative;

    .close-modal{
      display: inline-block;
      width: 80px;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      border: none;
      text-align:right;
    }
    &-container{
      background-color: $popup-bg;
      height: 80vh;
      overflow-y: auto;
    }
  }
  .modal-header-heading{
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    line-height: 45px;
    padding: 20px;

  }
}

#modal-education,
#modal-move,
#modal-salary{
  .dl-modal__content {
    max-width: 600px;
    &-container {
      max-height: 80vh;
      height: auto;
    }
  }
}

#modal-realtor-listing{
  .dl-modal__content {
    &-container {
      max-height: 610px;
    }
  }
}

#modal-history,
#modal-info,
#modal-education{
  .dl-modal__content {
    max-width: 800px;
  }
}

@media only screen and (max-width: 1600px) {
  .dl-modal{
    &__content{
      padding-top: 30px;
      .p-5{
        padding: 2.5rem !important;
      }
      .m-5{
        margin: 2.5rem !important;
      }
      .mb-5{
        margin-bottom: 2.5rem !important;
      }
    }
    .modal-header-heading{
      font-size: 30px;
      line-height: 40px;
      padding: 15px;
    }
  }


}
@media only screen and (max-width: 600px){
  .dl-modal{
    &__content{
      width: 90%;
      padding-top: 30px;
      &-container{
       .mx-5{
         margin-left: 10px !important;
         margin-right: 10px !important;
       }
        .key{
          color: $primary;
          font-weight: bold;
        }
      }
    }
    .modal-header-heading{
      font-size: 20px;
      line-height: 28px;
      padding: 10px;
    }
  }

}
