@import "../../../theme/abstracts/variables";

.side-bar-menu {
  position: fixed;
  top: $header-height;
  height: $min-content-height;
  background-color: $secondary;
  border-left: 1px solid $primary;
  right: 0;
  transition: all 0.3s ease-in-out;
  transform: translateX(205px);
  z-index: 95;
  &.opened,
  &:hover {
    transform: translateX(0);
    .menu {
      // left: calc(100% - 100px);
      cursor: initial;
    }
  }
  ul.menus {
    display: flex;
    flex-direction: column;
    padding-top: 74px;
    li {
      position: relative;
      border-top: 1px solid $primary;
      .menu-name,
      a {
        position: relative;
        z-index: 9;
        color: #fff !important;
        display: flex;
        align-items: center;
        width: 300px;
        height: 71px;
        padding: 0 12px 0 0;
        background-color: $secondary;
        text-transform: uppercase;
        transition: background-color 0.3s ease-in-out;
        overflow-wrap: anywhere;
        .icon {
          //margin-right: 25px;
          min-width: 99px;
          height: 99px;
          font-size: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          //border: 1px solid #fff;
          img {
            max-width: 80%;
          }
        }
        &.first-one.active {
          border-top: 1px solid $secondary;
        }
        &.active,
        &:hover {
          background-color: $primary;
        } 
      }
      &:last-child {
        border-bottom: 1px solid $primary;
      }
      .nested-menu {
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: calc(-100% + 5px);
        transform: translateX(100px);
        transition: all 0.5s ease-in-out;
        border-left: 1px solid $primary;
        z-index: 1;
        li a {
          width: 430px;
        }
      }
      &:hover {
        .nested-menu {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          left: calc(-100% + -132px);
        }
      }
    }
  }
  .menu {
    width: 100px;
    height: 74px;
    box-shadow: none;
    border: 1px solid $primary;
    transition: background-color 0.3s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    &:active,
    &:focus,
    &:hover {
      background-color: $primary-dark !important;
    }
  }
  .nav-icon {
    margin: 1em;
    width: 40px;
    &:after,
    &:before,
    span {
      background-color: #fff;
      border-radius: 3px;
      content: "";
      display: block;
      height: 5px;
      margin: 7px 0;
      transition: all 0.2s ease-in-out;
    }
    &.active {
      &:before {
        transform: translateY(12px) rotate(135deg);
      }
      &:after {
        transform: translateY(-12px) rotate(-135deg);
      }
      span {
        transform: scale(0);
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .side-bar-menu {
    transform: translateX(190px);
    height: $min-content-height-desktop;
    top: $header-height-desktop;
    z-index: 999;
    ul.menus {
      display: flex;
      flex-direction: column;
      padding-top: 60px;
      li {
        position: relative;
        .menu-name,
        a {
          width: 250px;
          height: 50px;
          font-size: 13px;
          .icon {
            min-width: 64px;
            height: 64px;
            font-size: 25px;
            img {
              max-width: 45%;
            }
          }
        }
      }
    }
    .nested-menu {
      li a {
        width: 330px !important;
      }
    }
    &:hover {
      .nested-menu {
        left: calc(-100% + -82px) !important;
      }
    }

    .menu {
      width: 65px;
      height: 60px;
    }
    &:hover {
      .menu {
        // left: calc(100% - 65px);
        left: 0;
      }
    }
  }
}

/*@media only screen and (max-width: 768px) {
    .side-bar-menu {
        transform: translateX(136px);
        height: $min-content-height-desktop;
        top: $header-height-desktop;
        z-index:999;
        ul.menus {
            display: flex;
            flex-direction: column;
            padding-top: 60px;
            li {
                position: relative;
              .menu-name,a {
                    width: 200px;
                    height: 50px;
                    font-size: 8px;
                    .icon {
                        min-width: 64px;
                        height: 64px;
                        font-size: 25px;
                        img{
                            max-width: 45%;
                        }
                    }
                }
            }
        }
        .nested-menu {
            li a{
                width: 150px !important;
            }
        }
        &:hover {
            .nested-menu {
                left: calc(-100% ) !important;
            }
        }

        .menu {
            width: 65px;
            height: 60px;
        }
        &:hover{
            .menu {
                left: calc(100% - 65px);

            }
        }
    }
}*/

@media only screen and (max-width: 768px) {
  .side-bar-menu {
    transform: translateX(160px);
    &:hover {
      transform: translateX(160px);
    }
    &.opened {
      transform: translateX(0);
      ul.menus {
        li {
          &:hover {
            .nested-menu {
              opacity: 1;
              visibility: visible;
              transform: translateX(0);
            }
          }
        }
      }
    }
    .menu {
      display: none !important;
    }
    ul.menus {
      display: flex;
      flex-direction: column;
      padding-top: 0 !important;
      li {
        position: relative;
        .menu-name,
        a {
          width: 150px;
          height: 40px;
          font-size: 8px;
          .icon {
            min-width: 35px;
            height: 35px;
            font-size: 22px;
            img {
              max-width: 45%;
            }
          }
        }
        &:hover {
          .nested-menu {
            opacity: 0;
            visibility: hidden;
            transform: translateX(100px);
          }
        }
      }
    }
    .nested-menu {
      li a {
        width: 150px !important;
      }
    }
    &:hover {
      .nested-menu {
        left: calc(-100%) !important;
      }
    }
  }
}
