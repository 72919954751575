@import "../../../../theme/abstracts/variables";


.weather{
    color: #fff;
        font-family: inherit;
    .title{
        padding : 20px 20px 0 20px
    }
    &__container{
        background-color: $popup-bg;
        margin: 20px;
        padding : 15px;
        .weather-temp{
            font-size : 70px;
            line-height : 70px;
            font-weight : 900;
            sup{
                font-size : 45px;
                left: -0.2em;
            }
        }
        .feels{
            font-size : 15px
        }
    }
    @media only screen and (max-width: 1600px) {

        &__container{
            margin: 12px;
            padding : 12px;
            .weather-icon{
                width : 60px;
                height : 60px;
                img{
                    max-width: 100%;
                }
            }
            .weather-temp{
                font-size : 45px;
                line-height : 45px;
                sup{
                    font-size : 30px;
                }
            }
            .feels.my-5{
                margin-bottom: 2rem !important;
                margin-top: 2rem !important;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        &__container{
            margin: 20px 10px;
            padding : 15px 0;
            .weather-temp{
                font-size : 35px;
                line-height : 35px;
                sup{
                    font-size : 20px;
                }
            }
            .feels.my-5{
                margin-bottom: 1rem !important;
                margin-top: 1rem !important;
                width: 100%;
            }
            .like, .pressure{
                width: 50%;
                font-size: 13px;
            }
            .like{
                justify-content: flex-end;
            }
        }
    }
}

