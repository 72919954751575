@import "../../../theme/abstracts/variables";

.destination-form {
  max-width: 750px;
  width: 100%;
  background-color: rgba($secondary, 0.8);
  padding: 15px;
  position: absolute;
  left: 0;
  z-index: 10;
  bottom: 65px;
  transition: all 0.8s ease-in-out;
  transform: translateY(50px);

  &.single {
    max-width: 400px;
  }

  &.active {
    transform: translateY(0);
    .destination-form-close {
      transform: rotate(0);
    }
  }
  &.dvm {
    max-width: 100%;
    position: relative;
    left: inherit;
    bottom: inherit;
    background-color: #000;
    margin: 50px 0;
    padding: 30px 80px;
    &.gray {
      background-color: #f3f3f3;
    }
    .destination-form-close {
      display: none;
    }
    .destination-form__title {
      text-align: center;
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
    }
    i {
      color: #fff;
    }
    .destination-form__container .field-group select {
      width: calc(50% - 5px);
    }
  }
  .destination-form-close {
    position: absolute;
    top: 14px;
    right: 30px;
    transition: all 0.3s ease-in-out;
    transform: rotate(180deg);
  }
  &__title {
    font-size: 17px;
    margin-bottom: 12px;
  }
  &__container {
    .field-group {
      width: calc(100% - 45px);
      select {
        width: 32%;
      }
    }
    .search-form-btn {
      background-color: $primary;
      width: 35px;
      height: 35px;
      margin-left: 0;
      border: 1px solid #fff;
      box-shadow: none;
      i {
        font-size: 16px;
        position: relative;
        top: 3px;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .destination-form {
    max-width: 650px;
    padding: 15px;
    bottom: 50px;
    transform: translateY(50px);
    .destination-form-close {
      position: absolute;
      top: 10px;
      right: 15px;
      transition: all 0.3s ease-in-out;
      transform: rotate(180deg);
    }
    &__title {
      font-size: 15px;
      margin-bottom: 12px;
    }
    &__container {
      .field-group {
        width: calc(100% - 52px);
      }
      .search-form-btn {
        width: 45px;
        height: 35px;
        border: 1px solid #fff;
        i {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .destination-form {
    padding: 12px 10px;
    &.dvm.gray {
      .destination-form__container {
        .field-group {
          // width: calc(100% - 38px);
          width: 100%;
          select {
            padding-left: 10px;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
    &__container {
      .field-group {
        // width: calc(100% - 38px);
        width: 100%;
      }
      .search-form-btn {
        width: 30px;
        height: 30px;
        i {
          font-size: 14px;
        }
      }
    }
    &.dvm {
      margin: 15px 0;
      padding: 12px 10px;
      &.gray {
        .destination-form__title {
          font-size: 18px;
        }
      }
      .destination-form__title {
        font-size: 22px;
      }
    }
  }
}
