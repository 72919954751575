.was-validated .form-control:invalid,
.form-control.is-invalid {
  background: transparent !important;
}
.passenger-details {
  .invalid-feedback {
    display: none !important;
  }
  .passport{
    .invalid-feedback {
      display: block !important;
    }
  }

  // .av-invalid{
  //   border: solid 1px #9c0000;
  // }
  padding: 100px 0;
  .passenger-details-form {
    background-color: #ffffff;
    &-header {
      padding: 18px 40px;
      h3 {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .passenger-alert {
      font-size: 13px;
      padding: 8px 40px;
      background-color: #fdf5cb;
    }
    .form-content {
      padding: 50px 40px;
      input {
        border: 1px solid #e2e3e6;
        height: 50px;
        font-size: 14px;
        padding: 10px 25px;
        margin-bottom: 15px;
        &.is-invalid {
          border-color: #dc3545;
        }
      }
      .form-radio {
        input {
          margin: 0;
          padding: 0;
          position: relative;
          &.is-invalid {
            &::before {
              position: absolute;
              content: "*";
              left: -15px;
              font-size: 17px;
              top: -4px;
              color: #e40017;
            }
            border-color: #dc3545;
          }
        }
      }
      .form-check {
        padding: 0;
        label {
          color: #707070;
          font-size: 15px;
        }
        input {
          margin: 0;
          padding: 0;
          // position: relative;
          // &.is-invalid {
          //   &::before {
          //     position: absolute;
          //     content: "*";
          //     left: -15px;
          //     font-size: 17px;
          //     top: -4px;
          //     color: #e40017;
          //   }
          //   border-color: #dc3545;
          // }
        }
      }
      .custom-radio {
        input {
          padding: 0;
          margin-bottom: 0 !important;
          height: 20px;
        }
        .custom-control-label {
          padding-left: 16px;
          &:before,
          &:after {
            width: 20px;
            height: 20px;
            top: -2px;
            left: -15px;
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          border-color: #9c0000;
          background-color: #9c0000;
        }
      }
      .dob {
        position: relative;
        .dob-label {
          position: absolute;
          top: 11px;
          background-color: #ffffff;
          color: #6c757d;
          font-weight: 400;
          font-size: 14px;
          padding: 3px 5px;
          left: 24px;
          
        }
        
      }
  
    }
  }

  
  .radio-style {
    span {
      margin-top: -4px;
    }
    .form-control {
      display: flex;
      align-items: center;
    }
  }
  .checkbox-field {
    .form-check {
      margin-top: 2px;
      input {
        height: 20px;
      }
    }
    span {
      margin-top: -4px;
    }
    .form-control {
      display: flex;
      align-items: flex-start;
    }
  }
  button {
    min-width: 230px;
  }
  .white-box {
    background-color: #ffffff;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    padding: 25px;
  }
  .flight-details {
    .direction {
      gap: 30px;
      font-size: 15px;
      color: #4e4e4e;
      .fd-location-label{
        text-transform: capitalize;
      }
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover{
          overflow: visible;
        }
      }
    }
    p {
      font-size: 13px;
      color: #4e4e4e;
      &.success {
        color: #0d9416;
        font-size: 12px;
      }
    }
  }
  .price-breakdown {
    h4 {
      font-size: 20px;
    }
    &-row {
      color: #4e4e4e;
      font-size: 15px;
      padding-top: 18px;
      &:not(:last-child) {
        border-bottom: 1px dashed #707070;
        padding-bottom: 18px;
      }
    }
  }
}

.intel-input-wrapper{
  .intl-tel-input {
    margin-bottom: 15px;
    &,
    input {
      width: 100%;
    }
    .selected-flag {
      width: 82px !important;
      position: relative !important;
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - 20px);
        right: 0;
        top: 10px;
        background-color: #e2e3e6;
      }
    }
    input {
      padding-left: 105px !important;
      outline: none !important;
    }
    &:hover, &:focus, &:focus-visible{
      border: none !important;
      outline: none !important;
    }
  }
  &.error{
    .intl-tel-input{
      input{
        border: 1px solid #dc3545;
        &:focus{
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69,.25);
        }
      }
    }
  }
}
.gender-field-row{
  .gender-label{
    position: relative;
    top: -6px;
  }
  .av-invalid.is-invalid{
    .gender-radio-label{
      color: #dc3545
    }
  }
}
ul.country-list {
  width: 100%;
  li {
    display: block;
  }
}

.checkbox-field {
  .form-control {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 575px) {
  .passenger-details {
    padding: 50px 0;
    .container {
      padding: 0;
    }
    .flight-details {
      text-align: center;
    }
    .passenger-details-form {
      .form-content {
        padding: 15px;
      }
    }
  }
}
