.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.text-primary {
  color: $primary !important;
}

.font-secondary {
  font-family: $secondary-font-family;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}
.f-22 {
  font-size: 22px;
}

.pointer {
  cursor: pointer;
}
