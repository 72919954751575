.filter-dropdown {
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    width: auto;
    height: auto;
    min-width: auto;
    &:active,
    &:hover{
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
      i{
        color: $primary;
      }
    }

    i {
      font-size: 28px;
      transition: color .3s ease-in-out;
      color: #ffffff;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu{
    width: 200px;
    padding: 10px 0;
    button{
      font-size: 18px;
      line-height: 30px;
    }
  }
  &.show{
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      i {
       color: $primary;
      }
    }

  }
}
