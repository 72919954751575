.historical-date-form {
  padding-bottom: 15px;
  .historical-date-form-container {
    gap: 15px;
    .input-row {
      flex-grow: 1;
      label {
        font-size: 12px;
      }
      input {
        height: 32px;
        font-size: 13px;
        color: #000000;
      }
    }
    button {
      height: 34px;
    }
  }
}
.content-weather-details{
  button {
    color: #ffffff !important;
    font-size: 15px;
    font-weight: 600;
    transition: .3s ease-in-out;
    
    &:focus, &.focus {
      outline: 0;
      box-shadow: none;
  }
    &:hover{
      border-bottom: 2px solid #9c0000;
    }
    &.active{
      border-bottom: 2px solid #9c0000;
    }
  }
}
.forecasting {
  background-color: #000000;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0px 15px;
  .forecasting-container {
    gap: 15px;
  }
  .key-value {
    flex: 1;
    .key {
      font-size: 12px;
    }
    .value {
      font-weight: 600;
      font-size: 12px;
    }
  }
}
@media (max-width: 768px) {
  .forecasting {
    .forecasting-container {
      flex: 1;
      flex-wrap: wrap;
      border-bottom: 1px solid #fff3;
      padding-bottom: 10px;
      background: #ffffff0f;
    }
    .key-value {
      flex: none;
    }
  }

  .historical-date-form {
     .historical-date-form-container {
        flex-direction: column;
        align-items: center !important;

        .input-row {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }
      padding-bottom: 15px;
    
  }
}
