@import "../../../theme/abstracts/variables";
@import "../SideBarMenu/SideBarMenu.scss";

.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: #efefef;
  top: 0;
  right: 0;

  .guest-login {
    // width: 180px;
    padding: 0 30px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    transition: color 0.3s;
    border-left: 1px solid #dadada;

    .icon-login {
      margin-top: 5px;
    }

    span {
      margin-left: 10px;
      // margin-top: 15px;
      font-size: 14px;
      line-height: 21px;
    }

    &:hover {
      color: $primary;
    }
  }

  .header-container {
    border-bottom: 1px solid #dadada;
  }
  .logo {
    font-size: 0;
    width: 275px;
    height: $header-height;
    // height: 65px;
    a {
      min-width: 100px;
      max-width: 100px;
      padding: 0 10px;
    }
  }
  ul {
    li {
      height: $header-height;
      display: inline-flex;
      align-items: center;
      padding: 0 30px 0 40px;

      a,
      button {
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        i {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          margin-right: 10px;
          width: 18px;
          position: relative;
          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    &.contact-info {
      li {
        border-right: 1px solid $border-color-primary;
      }
    }
    &.menu-link {
      li {
        padding: 0 30px;
        border-left: 1px solid $border-color-primary;
        a,
        button {
          height: $header-height;
          position: relative;
          border: none;
          box-shadow: none;
          font-size: 14px;
          font-weight: 600;
          &.active {
            &:before {
              content: "";
              width: 100%;
              height: 2px;
              background-color: $primary;
              position: absolute;
              left: 0;
              bottom: 2px;
            }
          }
        }
      }
    }
  }
  .menu-btn {
    width: 44px;
    height: 44px;
    box-shadow: none;
    border: none;
    transition: background-color 0.3s ease-in-out;
    display: none !important;
    &:active,
    &:focus,
    &:hover {
      background-color: $primary-dark !important;
    }
    .nav-icon {
      width: 30px;
      &:after,
      &:before,
      span {
        background-color: #fff;
        border-radius: 3px;
        content: "";
        display: block;
        height: 3px;
        margin: 5px 0;
        transition: all 0.2s ease-in-out;
      }
    }
    &.active {
      .nav-icon {
        &:before {
          transform: translateY(5px) rotate(135deg);
        }
        &:after {
          transform: translateY(-11px) rotate(-135deg);
        }
        span {
          transform: scale(0);
        }
      }
    }
  }
}

.guest-logged-in {
  font-size: 14px;
}
@media only screen and (max-width: 1600px) {
  .header {
    .logo {
      width: 210px;
      height: $header-height-desktop;
      img {
        width: 100%;
        max-width: 190px;
      }
    }
    ul {
      li {
        height: $header-height-desktop;
        padding: 0 10px 0 15px;
        button,
        a {
          font-size: 11px !important;
          height: $header-height-desktop !important;
          span {
            padding-right: 5px;
          }
        }
      }
      &.menu-link {
        li {
          padding: 0 12px;
          a {
            height: $header-height-desktop;
          }
        }
      }
    }
  }

  .guest-login {
    span {
      font-size: 11px !important;
    }
  }

  .guest-logged-in {
    font-size: 11px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    .logo {
      margin-left: 10px;
      width: 120px;
    }
    ul {
      li {
        a,
        button {
          span {
            display: none;
          }
        }
      }
    }
    .menu-btn {
      display: flex !important;
    }
  }
}
