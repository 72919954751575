.bound-accordion {
.card{
  &-header{
    font-size: 18px;
  }
  &-body{
    table{
      width: 100%;
      tr{
        td, th{
          padding: 5px 10px;
          border: 1px solid #ddd;
          text-align: center;
          text-transform: capitalize;
        }
        th{
          background: #fff;
          color: #000;
          font-weight: 800;

        }
        td{
          font-size: 15px;
        }
      }
    }
  }
}
}