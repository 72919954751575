.banner-slider{
  min-height: 500px;
  height: 100vh;
  position: relative;
  .banner-slide{
    min-height: 500px;
    height: 100vh;
    background-size: cover;
    background-position: center;
    .banner-slide__content{
      padding: 245px 0;
      .slide-content{
        z-index: 1;
        max-width: 900px;
        h2{
          max-width: 520px;
        }
        p{
          max-width: 450px;
          margin: 50px 0;
        }
      }
      .slide-img{
        width: 800px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
        img{
          width: 100%;
        }
      }
    }

  }
  .slick-dots{
    position: absolute;
    display: flex !important;
    top: 0;
    right: 0;
    z-index: 99;
    width: 25px;
    flex-direction: column;
    justify-content: CENTER;
    align-items: center;
      li{
        width: auto;
        height: auto;
        margin: 0;
        button{
          padding: 0;
          background: #fff;
          margin: 6px  25px 6px 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          transition: all .3s ease-in-out;
          &:before{
            display: none;
          }

        }
        &.slick-active{
          button{
            height: 25px;
            border-radius: 10px;
          }
        }
      }
  }
}


@media only screen and (max-width: 1400px) {
  .banner-slider{
    .banner-slide{
      .banner-slide__content{
        .slide-content{
          z-index: 1;
          max-width: 800px;
        }
        .slide-img{
          width: 700px;
          right: 50px;
          top: 56%;
        }
      }

    }
  }

}