.hotel-details-modal{
    padding: 25px;
    color: #fff;
    .hotel-details__header{
        .hotel-name{
            h3{
                font-size: 28px;
                font-weight: 700;
                padding-right: 15px;
            }
            span{
                font-size: 10px;
                padding-left: 5px;
                margin-top: -4px;
            }
        }
        address{
            font-size :13px;
        }

    }
    .overview{
        h4{
            margin: 12px 0;
            font-size: 20px;
            font-weight: 600;
        }
        p{
            font-size: 15px;
            font-weight: 100;
        }
    }
    .render{
        font-size: 14px;
        font-weight : 500;
        p{
            font-size: 14px;
        }
    }
    .facilities{
        ul{
            li{
                font-size: 16px;
                font-weight: 500;
                &:before{
                    top: 4px;
                    left: 25px;
                }
            }
        }
    }
}
@media only screen and (max-width: 600px){
    .hotel-details-modal{
        padding: 12px;
        .hotel-details__header{
            .hotel-name{
                margin-bottom: 10px;
                h3{
                    font-size: 20px;
                    padding-right: 0;
                }
            }
            address{
                font-size :12px;
            }

        }
        .overview{
            h4{
                margin: 8px 0;
                font-size: 16px;
            }
            p{
                font-size: 14px;
                line-height: 18px;
            }
        }
        .render{
            font-size: 14px;
            line-height: 18px;
            p{
                font-size: 14px;
                line-height: 18px;
            }
        }
        .facilities{
            ul{
                li{
                    font-size: 14px;
                    padding-left: 20px;
                    padding-right: 10px;
                    &:before{
                        top: 5px;
                        left: 5px;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }


}
